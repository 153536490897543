import * as React from "react";
import {
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    List,
    ListButton,
    RefreshButton,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar
} from 'react-admin';
import {Groups} from './groups';
// import {makeStyles} from '@mui/styles';
// import {styled} from "@mui/material";

export const UserList = (props) => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="username" sortable={false}/>
            <TextField source="firstName" sortable={false}/>
            <TextField source="lastName" sortable={false}/>
            <TextField source="email" sortable={false}/>
        </Datagrid>
    </List>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="username" validate={[required()]}/>
            <TextInput source="firstName" validate={[required()]}/>
            <TextInput source="lastName" validate={[required()]}/>
            <TextInput source="email" validate={[required()]}/>
            <BooleanInput source="enabled"/>
            <BooleanInput source="emailVerified"/>
        </SimpleForm>
    </Create>
);

const UserEditActions = ({data, resource}) => (
    <TopToolbar>
        <ListButton />
        <DeleteWithConfirmButton record={data} undoable={false}/>
        <RefreshButton/>
    </TopToolbar>
);

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

// const useAsideStyles = styled('div')(theme => ({
//     root: {
//         width: 400,
//         [theme.breakpoints.down('md')]: {
//             display: 'none',
//         },
//     },
// }));



const Aside = () => {
    // console.log('Aside');
    // const classes = useAsideStyles();
    return (
        <div sx={{width: 400}}>
            <Groups/>
        </div>
    );
};

const UserTitle = props => {
    return <span>{props.record ? `${props.record.username}` : ''}</span>;
};

export const UserEdit = (props) => (
    <Edit {...props} aside={<Aside/>} title={<UserTitle />} actions={<UserEditActions/>}>
        <SimpleForm toolbar={<UserEditToolbar/>}>
            <TextInput source="username" disabled={true} validate={[required()]}/>
            <TextInput source="firstName" validate={[required()]}/>
            <TextInput source="lastName" validate={[required()]}/>
            <TextInput source="email" validate={[required()]}/>

            <BooleanInput source="enabled"/>
            <BooleanInput source="emailVerified"/>
        </SimpleForm>
    </Edit>
);
