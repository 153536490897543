import {fetchUtils} from "react-admin";

const fetchJson = (keycloak) => {
    // for data provider, it writes token to an authorization header
    const fetcher = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: "application/json" });
        }
        if (keycloak.token) {
            options.headers.set("Authorization", "Bearer " + keycloak.token);
        } else if (localStorage.getItem("token")) {
            options.headers.set(
                "Authorization",
                "Bearer " + localStorage.getItem("token")
            );
        }
        return fetchUtils.fetchJson(url, options);
    };

    return fetcher;
};

export default fetchJson;
