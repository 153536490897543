import './App.css';

import {Admin, Resource, useTranslate} from 'react-admin';
import {ThemeProvider} from 'styled-components';
import React, { useState } from 'react';
import fetchJson from "./fetchJson";

import useAuthProvider from "./authProvider"
import keycloakOptions from "./Keycloak";
import Keycloak from "keycloak-js";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import createKeycloakProvider from "./resources/keycloakDataProvider";
import {UserCreate, UserEdit, UserList} from "./resources/users";

import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from './i18n/de';

import {theme} from "./theme";
import {useParams} from "react-router";

let keycloak = Keycloak(keycloakOptions);

const i18nProvider = polyglotI18nProvider(locale => {
    return germanMessages;
}, 'de');

const onToken = () => {
    if (keycloak.token && keycloak.refreshToken) {
        localStorage.setItem("token", keycloak.token);
        localStorage.setItem("refresh-token", keycloak.refreshToken);
    }
};

const onTokenExpired = () => {
    keycloak
        .updateToken(30)
        .then(() => {
            console.log("successfully get a new token", keycloak.token);
        })
        .catch(() => {
            console.error("failed to refresh token");
        });
};

const CustomAdminWithKeycloak = ({realm}) => {
    const customAuthProvider = useAuthProvider(keycloakOptions.clientId);
    const translate = useTranslate();
    const [tenantId, setTenantId] = useState(null)
    if(!realm) {
        realm = keycloakOptions.realm;
    }

    console.log('CustomAdminWithKeycloak: ' +realm);
    if(!tenantId) {
        customAuthProvider.getIdentity()
            .then((currentUser) => {
                console.dir(currentUser);
                if(!currentUser.tenantId ) {
                    setTenantId(1);
                } else {
                    setTenantId(currentUser.tenantId)
                }
            })
    }
    console.log('tenantId: ' + tenantId);
    const url = keycloakOptions.url + 'admin/realms/'  + realm;
    const customDataProvider = createKeycloakProvider(url, fetchJson(keycloak), tenantId);
    return (
        <Admin
            theme={theme}
            title={translate('apptitle')}
            dataProvider={customDataProvider}
            authProvider={customAuthProvider}
            i18nProvider={i18nProvider}
            loginPage={false}
        >
            <Resource name="users" create={UserCreate} edit={UserEdit} list={UserList} />
            <Resource name="groups" />
        </Admin>
    );
};

const App = () => {
    const [realm, setRealm] = useState(null);
    const {realmId} = useParams();
    console.log(realmId);
    console.log(realm)
    if(realmId) {
        keycloakOptions.realm = realmId
        if(!realm || realm !== realmId) {
            setRealm(realmId)
        }
    } else if(realm){
        keycloakOptions.realm = realm
    }

    console.log('App: ' +realm);

    return  (
        <ReactKeycloakProvider
            authClient={keycloak}
            LoadingComponent={<div></div>}
            initOptions={keycloakOptions}
            onTokens={onToken}
            onTokenExpired={onTokenExpired}
        >
                <CustomAdminWithKeycloak realm={realm}/>
                <ThemeProvider theme={theme}>
                    <footer/>
                </ThemeProvider>
        </ReactKeycloakProvider>
    ) ;
};

export default App;
