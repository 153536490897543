import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import keycloakOptions from "../Keycloak";
import {Box, Card, Table, Button, TableBody, TableRow, TableCell, CardContent, Typography} from '@mui/material';
import {useKeycloak} from "@react-keycloak/web";
import fetchJson from "../fetchJson";
// import {makeStyles} from '@mui/styles';
import {useTranslate, useNotify, SimpleForm, PasswordInput, required, useRecordContext} from 'react-admin';


// const useStyles = makeStyles({
//     icon: {paddingRight: '0.5em'},
//     link: {
//         display: 'inline-flex',
//         alignItems: 'center',
//     },
// });

export const Groups = () => {
    const record = useRecordContext();
    const basePath = '/users';

    let recordId = 0
    if (record) {
        recordId = record.id;
    }

    const [available, setAvailable] = useState([]);
    const [assigned, setAssigned] = useState([]);

    const {keycloak} = useKeycloak();
    var fetcher = fetchJson(keycloak);

    const notify = useNotify();
    // const classes = useStyles();
    const translate = useTranslate();

    const updateAssignedGroups = useCallback(() => {
        fetcher(keycloakOptions.url + 'admin/realms/' + keycloakOptions.realm + basePath + '/' + recordId + '/groups').then(a => {
            // Group wird sonst ständig gerendert
            console.log('updateAssignedGroups:' + a);
            if(a.json instanceof Array && a.json?.length !== assigned.length) {
                setAssigned(a.json);
            }
        })
    }, [basePath, fetcher, recordId, assigned])

    useEffect(() => {
        if (available.length === 0) {
            fetcher(keycloakOptions.url + 'admin/realms/' + keycloakOptions.realm + '/groups').then(a => {
                setAvailable(a.json);
            })
        }
        console.log('updateAssignedGroups');
        updateAssignedGroups();
    }, [fetcher, updateAssignedGroups, available])

    var joinGroup = (group) => {
        const url = keycloakOptions.url + 'admin/realms/' + keycloakOptions.realm + basePath + '/' + recordId + '/groups/' + group.id;
        fetcher(url, {
            method: 'PUT',
            body: JSON.stringify({
                realm: keycloakOptions.realm,
                userId: recordId,
                groupId: group.id,
            }),
        }).then(function () {
            updateAssignedGroups();
        });
    }

    var leaveGroup = (group) => {
        const url = keycloakOptions.url + 'admin/realms/' + keycloakOptions.realm + basePath + '/' + recordId + '/groups/' + group.id;
        fetcher(url, {
            method: 'DELETE',
        }).then(function () {
            updateAssignedGroups();
        });
    }

    const passwordRecord = {
        type: 'password',
        temporary: false,
        value: ''
    };

    var setPassword = (credentials) => {
        const url = keycloakOptions.url + 'admin/realms/' + keycloakOptions.realm + basePath + '/' + recordId + '/reset-password';
        fetcher(url, {
            method: 'PUT',
            body: JSON.stringify(credentials),
        }).then(function () {
            notify(translate('resources.users.passwordupdated'));
        });
    }

    return (
        <div>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.groups.available')}
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {available.map(function (group, index) {
                                    for (var i = 0; i < assigned.length; i++) {
                                        if (assigned[i].id === group.id) {
                                            return null;
                                        }
                                    }
                                    return <TableRow key={group.id}>
                                        <TableCell>{group.name} </TableCell>
                                        <TableCell align="right"><Button onClick={() => joinGroup(group)} size="small"
                                                                         sx={{display: 'inline-flex', alignItems: 'center',}}
                                                                         color="primary">{translate('resources.groups.add')}</Button></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.groups.assigned')}
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {assigned.map(function (group, index) {
                                    return <TableRow key={group.id}>
                                        <TableCell>{group.name} </TableCell>
                                        <TableCell align="right"><Button onClick={() => leaveGroup(group)} size="small"
                                                                         sx={{display: 'inline-flex', alignItems: 'center',}}
                                                                         color="primary">{translate('resources.groups.remove')}</Button></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
            <Box m="0 0 1em 1em">
                <Card>
                    <SimpleForm redirect={false} record={passwordRecord} save={setPassword}>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.users.password')}
                        </Typography>
                        <PasswordInput label={translate('resources.users.password')} source="value"
                                       validate={[required()]}/>
                    </SimpleForm>
                </Card>
            </Box>
        </div>
    );
}
