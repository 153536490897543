import { TranslationMessages } from 'react-admin';
import germanMessages from 'ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    apptitle: "Benutzerverwaltung",
    resources: {
        groups: {
            available: "Verfügbare Gruppen",
            assigned: "Zugewiesen Gruppen",
            add: "hinzufügen",
            remove: "entfernen",
        },
        users: {
            name: 'Benutzer |||| Benutzer',
            password: 'Passwort',
            savepassword: 'setzen',
            passwordupdated: 'Das Passwort wurde aktualisiert.',
            fields: {
                username: 'Benutzername',
                lastName: 'Nachname',
                firstName: 'Vorname',
                enabled: 'aktiviert',
                emailVerified: 'Email bestätigt',
            },
        },
    }
}

export default customGermanMessages;
